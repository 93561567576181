import React from "react";
import TrophyImage from "../resources/successes/trophies.jpeg"

function Successes() {
    return (
        <div className="flex justify-between flex-row">
            <div className="w-full ml-16 mt-10 mb-10 md:w-2/3">
                <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl">Our Successes</h1>
                <p className={"text-2xl mr-18 font-normal text-gray-500 text-gray-400"}>Our members have won various prestigious debating tournaments, both domestically and internationally. We have been crowned champions in Berlin, Amsterdam, Oxford and Hong Kong. LDU has won the European championships several times, most recently in 2020. Additionally, every year, we run one of the largest debating tournaments in the Netherlands, Leiden Open.</p>
            </div>
            <img className="image-about ml-10 mr-16 mt-10 mb-10 w-1/3 aspect-4/3" src={TrophyImage} alt="Trophies"/>
        </div>
    );
    // TODO: bad responsiveness
}

export default Successes;