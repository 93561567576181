import {scrollToContact} from "./Navbar";

function Joining() {
    return (
        <div class={"bg-gray-900 pt-4"}>
            <h1 class={"mb-4 text-4xl font-extrabold text-white text-center leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl"}>
                Joining the Union
            </h1>

            <div class="flex flex-wrap justify-center space-x-0 md:space-x-4 mt-4">
                <div class="bg-white rounded-lg shadow-lg p-6 w-full md:w-1/5 mb-4 md:mb-8">
                    <h3 class="text-s">Step 1</h3>
                    <h2 class="text-xl font-bold mb-2">Get in touch</h2>
                    <p class="text-gray-700">If you want to attend a debate night or social event, please <a onClick={scrollToContact} class={"text-blue-700 underline cursor-pointer"}>get in
                        touch</a>.</p>
                </div>

                <div class="bg-white rounded-lg shadow-lg p-6 w-full md:w-1/5 mb-4 md:mb-8">
                    <h3 class="text-s">Step 2 (optional)</h3>
                    <h2 class="text-xl font-bold mb-2">Join the groupchat</h2>
                    <p class="text-gray-700">Our Whatsapp community is the easiest way to stay up to date on our debate nights and general union news.</p>
                </div>

                <div class="bg-white rounded-lg shadow-lg p-6 w-full md:w-1/5 mb-4 md:mb-8">
                    <h3 class="text-s">Step 3</h3>
                    <h2 class="text-xl font-bold mb-2">Visit</h2>
                    <p class="text-gray-700">We debate every Tuesday and Friday at 19:00 at the <a class={"text-blue-700 underline"} href={"https://www.staff.universiteitleiden.nl/locations/kamerlingh-onnes-building"}>Kamerlingh Onnes</a> and <a class={"text-blue-700 underline"} href={"https://www.staff.universiteitleiden.nl/locations/plexus-student-centre"}>Plexus</a> buildings, respectively.</p>
                </div>

                <div class="bg-white rounded-lg shadow-lg p-6 w-full md:w-1/5 mb-4 md:mb-8">
                    <h3 class="text-s">Step 4</h3>
                    <h2 class="text-xl font-bold mb-2">Sign up</h2>
                    <p class="text-gray-700">When you decide you're ready, you can become an official member by filling in the <a class={"text-blue-700 underline"} href={"https://forms.gle/7vpFEoU4WKefjvDS6"}>membership
                        form</a>.</p>
                </div>
            </div>
        </div>
    );
}

export default Joining;